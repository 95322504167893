<template>
  <AdminView>
    <router-link class="back-link" :to="{ name: 'ProgramsList' }">&lt; Retour</router-link>

    <div v-if="mode === 'edit'">
      <h1 class="title-1">Modifier le programme</h1>
      <ProgramForm @removeProgram="removeProgram" v-if="!isProgramEmpty" :program="program" mode="edit" />
    </div>

    <div v-if="mode === 'add'">
      <h1 class="title-1">Ajouter un programme</h1>
      <ProgramForm mode="add" />
    </div>
  </AdminView>
</template>


<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import AdminView from '@/components/AdminView'
import ProgramForm from '@/components/Forms/ProgramForm'

export default {
  name: 'Program',
  props: {
    mode: {
      type: String,
      default: 'edit'
    }
  },
  components: {
    AdminView, ProgramForm
  },
  async created () {
    await this.$store.dispatch('admin/getPrograms')
  },
  computed: {
    ...mapGetters({
      getProgram: 'admin/getProgram'
    }),
    program () {
      const programId = this.$route.params.id
      return this.getProgram(programId)
    },
    isProgramEmpty () {
      return _.isEmpty(this.program)
    }
  },
  methods: {
    async removeProgram(id){
      await this.$store.dispatch('admin/removeProgram', id)
    this.$router.push({ name: "ProgramsList" })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
